import React from "react";
import {Link} from "react-router-dom";
import './NavBar.css';
import banner from '../images/bannerlogo_resize.svg'
import OpenPopUp from './OpenPopUp';
import OpenPopUp2 from './OpenPopUp2';

function NavBar4 (){
    return(
        <ul class="fixedTop2">
            <li class="liNavbar"><Link to="/" className="mid">Home</Link></li>
            <li class="liNavbar"><Link to="/About" className="mid">About</Link></li>
            <li class="liNavbar"><div class="spacingRight"><Link to="/Contact" className="mid">Contact Us</Link></div></li>
            <li class="liNavbar"><OpenPopUp2 /></li>
        </ul>
    )
}

export default NavBar4