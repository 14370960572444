import React from "react";
import './App.css';
import {Route, Link} from 'react-router-dom';
import { useState, useEffect } from 'react';
import Home from "./components/Home";
import Home2 from "./components/Home2";
import About2 from "./components/About2";
import Contact2 from "./components/Contact2";

import TermsOfUse from "./components/TermsOfUse";
import TermsOfUse2 from "./components/TermsOfUse2";
import PrivacyPolicy from "./components/PrivacyPolicy";
import PrivacyPolicy2 from "./components/PrivacyPolicy2";
import About from "./components/About";
import Legal from "./components/Legal";
import Contact from "./components/Contact";
import GoToApp from "./components/GoToApp";

import NavBar from './components/NavBar';
import NavBar2 from './components/NavBar2';
import NavBar3 from './components/NavBar3';
import NavBar4 from './components/NavBar4';
import Footer from './components/Footer';
import Footer2 from './components/Footer2';
const getWidth = () => window.innerWidth 
|| document.documentElement.clientWidth 
|| document.body.clientWidth;

function useCurrentWidth() {
// save current window width in the state object
let [width, setWidth] = useState(getWidth());

// in this case useEffect will execute only once because
// it does not have any dependencies.
useEffect(() => {
  // timeoutId for debounce mechanism
  let timeoutId = null;
  const resizeListener = () => {
    // prevent execution of previous setTimeout
    clearTimeout(timeoutId);
    // change width from the state object after 150 milliseconds
    timeoutId = setTimeout(() => setWidth(getWidth()), 150);
  };
  // set resize listener
  window.addEventListener('resize', resizeListener);
  
  if (window.location.href.includes("promo")) {
    window.location.href = "/"
  } 
  
  // clean up function
  return () => {
    // remove resize listener
    window.removeEventListener('resize', resizeListener);
  }

}, [])

return width;
}


function App() {
  let width = useCurrentWidth();

  if (width > 1400) {
    return (
      <div className="App">
        <NavBar />
        <Route exact path="/" component={Home} />
        <Route exact path="/TermsOfUse" component={TermsOfUse} />
        <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/About" component={About} />
        <Route exact path="/Contact" component={Contact} />
        <Footer />
      </div>
    );
  } else if (width < 1400 && width >= 1150) {
    return (
      <div className="App">
        <NavBar />
        <Route exact path="/" component={Home} />
        <Route exact path="/TermsOfUse" component={TermsOfUse} />
        <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/About" component={About} />
        <Route exact path="/Contact" component={Contact} />
        <Footer />
      </div>

    );
  } else if (width < 1150 && width >= 1000) {
    return (
      <div className="App">
        <NavBar2 />
        <Route exact path="/" component={Home} />
        <Route exact path="/TermsOfUse" component={TermsOfUse} />
        <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/About" component={About} />
        <Route exact path="/Contact" component={Contact} />
        <Footer />
      </div>
    );
  } else if (width < 1000 && width >= 800) {
    return (
      <div className="App">
        <NavBar3 />
        <Route exact path="/" component={Home2} />
        <Route exact path="/TermsOfUse" component={TermsOfUse} />
        <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/About" component={About} />
        <Route exact path="/Contact" component={Contact2} />
        <Footer2 />
      </div>
    );
  } else {
      return (
        <div className="App">
          <NavBar4 />
          <Route exact path="/" component={Home2} />
          <Route exact path="/TermsOfUse" component={TermsOfUse2} />
          <Route exact path="/PrivacyPolicy" component={PrivacyPolicy2} />
          <Route exact path="/About" component={About2} />
          <Route exact path="/Contact" component={Contact2} />
          <Footer2 />
        </div>
      );
  }
  
}

export default App;
