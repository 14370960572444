import React, { useState } from 'react';
import Modal from 'react-modal';
import GoToApp from "./GoToApp";
import Swal from 'sweetalert2';

const customStyles = {
  overlay: {
    backgroundColor: "rgb(0, 0, 0, 0.75)",
  },  
  content: {
      padding:0,
      margin:0,
      width:570,
      height:290,
      position: 'absolute', left: '50%', top: '50%',
      transform: 'translate(-50%, -50%)',
      borderRadius:40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor:"#272727",
      border:0,
    },
  };

function OpenPopUp (){
    let subtitle;
    const [modalIsOpen, setIsOpen] = useState(false);
    
    function openModal() {
      setIsOpen(true);
    }
  
    function afterOpenModal() {
      // references are now sync'd and can be accessed.
      subtitle.style.color = '#f00';
    }

    function closeModal() {
        // setIsOpen(false);
        window.location.reload();
      }

    return(
        <div>
            <button onClick={()=> window.open("https://parlaye.app.link/Website", "_blank")} to="/GoToApp" class="join">Download the App</button>
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <GoToApp />
            </Modal>
        </div>
    );
};

export default OpenPopUp;