import React from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";
import { useState, useEffect }  from 'react';
import './GoToApp.css';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Swal from 'sweetalert2';

const url = `https://parlaye.us8.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

// simplest form (only email)
const SimpleForm = () => <MailchimpSubscribe url={url}/>

const ReloadPage = () => window.location.reload()

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });
    if (status=="success") {
      Swal.fire({
        position: 'center',
        icon: 'success',
        width:175,
        background:"#272727",
        color: '#F0F3F4',
        title: '',
        showConfirmButton: false,
        timer: 2000
      });
      status="Null";
      // setTimeout(ReloadPage, 4000);
    };
    if (status=="error") {
      Swal.fire({
        position: 'center',
        icon: 'error',
        width:175,
        background:"#272727",
        color: '#F0F3F4',
        title: '',
        showConfirmButton: false,
        timer: 2000
      });
      status="Null";
    }

  return (
    <div
      style={{
        background: "#272727",
        borderRadius: 2,
        padding: 10,
        display: "block",
        borderRadius:40,
        width:300,
        textAlign:"center"
      }}
    >
      <h2
        style={{
          padding:20,
          color:"#FFFFFF",
          fontFamily:"Lato-Regular",
          fontSize:25,
          textAlign:"center",
          marginBottom:-10,
      }}>
        Enter Email Address
      </h2>
      <p
        style={{
          padding:0,
          color:"#E5E5E5",
          paddingLeft:20,
          paddingRight:20,
          fontFamily:"Lato-Regular",
          fontSize:10,
          textAlign:"center",
          marginBottom:15,
      }}>
        Subscribe to our mailing list to get exclusive rewards and stay informed on our app launch and latest updates.
      </p>

      <input
        style={{ marginBottom:15,fontSize: 10, boxSizing:"border-box", border:"1px solid #E5E5E5",fontFamily:"Lato-Regular", textAlign:"left", width:246, borderRadius:30, padding: 5, color: "#FFFFFF", height:30, paddingLeft:10,backgroundColor:"#272727",fontFamily:"Lato-Regular"}}
        ref={node => (email = node)}
        type="email"
        placeholder="Enter your email address"
        required
      />

      <br />
      <button style={{ fontWeight:"bold",marginBottom:20, height:30,fontSize: 10, color: "#FFFFFF", cursor: 'pointer', textAlign:"center", backgroundColor:"#19E094", width:245, borderRadius: 30, padding: 5 }} onClick={submit}>
        Submit
      </button>
    </div>
  );
};

const GoToApp2 = (props) => {
    return (
        <div>
        <MailchimpSubscribe
          url={url}
          render={({ subscribe, status, message }) => (
            <CustomForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />
          )}
        />
        </div>
    );
};

export default GoToApp2;