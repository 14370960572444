import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";
import './Contact.css';
import contact from '../images/Contact.png';
import { useEffect } from "react";
import emailjs from '@emailjs/browser';
import Swal from 'sweetalert2';

function Contact() {
    const form = useRef();

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_55sxxmg', 'template_7i38rgs', form.current, 'XkfOv-huG2N_Rct_W')
          .then((result) => {
              console.log(result.text);
              Swal.fire({
                position: 'center',
                icon: 'success',
                width:175,
                background:"#272727",
                color: '#F0F3F4',
                title: '',
                showConfirmButton: false,
                timer: 2000
              })
          }, (error) => {
              console.log(error.text);
              Swal.fire({
                position: 'center',
                icon: 'error',
                width:175,
                background:"#272727",
                color: '#F0F3F4',
                title: '',
                showConfirmButton: false,
                timer: 2000
              })
          });
        e.target.reset();
    }

    useEffect(() => {
        setTimeout(() => window.scrollTo(0,0));
      });

    return (
        <div class="Contact">
            <div class="ContactBox">
            <table>
                <tr>
                    <th>
                        <ul class="headerUL">
                            <li class="headerList">
                                <h2 class="Header2Contact">Get in Touch</h2>
                            </li>
                            <li class="headerList"><p class="PContact">Shoot us an email if you have any questions related to Parlaye. We look forward to hearing from you!</p></li>
                            <li>
                                <form ref={form} onSubmit={sendEmail}>
                                    <label>
                                        <input type="text" placeholder="First Name" class="contactInput" name="first_name" required/>
                                    </label>
                                    <label>
                                        <input type="text" placeholder="Last Name" class="contactInput" name="last_name" required/>
                                    </label>
                                    <label>
                                        <input type="email" placeholder="Email" class="contactInput" name="email" required/>
                                    </label>
                                    <label>
                                        <textarea name="description" class="contactInputDesc" placeholder="Description" cols="40" rows="5" required></textarea>
                                    </label>
                                    <input type="submit" class="submitContact" value="Submit" />
                                </form>
                            </li>
                        </ul>
                    </th>
                    <th><img src={contact} alt="" class="aboutImg"/></th>
                </tr>
            </table>
                
                
                
            </div>
        </div>


    )
}

export default Contact;