import React from 'react';
import ReactDOM from 'react-dom';
import {Link} from "react-router-dom";
import './About.css';
import contact_1 from '../images/Contact_1.png';
import contact_2 from '../images/Contact_2.png';
import contact_3 from '../images/Contact_3.png';
import { useEffect } from "react";

function About2() {
    useEffect(() => {
        setTimeout(() => window.scrollTo(0,0));
      });

    return (
        <div class="About">
            <table class="aboutTable">
                <tr>
                    <th>
                        <ul class="ContactText20">
                            <li class="align1">
                                <h2 class="Header2About20">Entertaining</h2>
                            </li>
                            <li><p class="PAbout20">Our mission is to revolutionize the way people experience fantasy sports. We believe that the key to success in fantasy sports lies in the power of community and head to head play. That's why we've created a mobile application that leverages the power of community to offer our users parlays that never miss which provide maximum entertainment value and rewards.</p></li>
                            <li><img src={contact_1} alt="" class="contact120"/></li>
                        </ul>
                    </th>
                </tr>
                
                <tr>
                    <th>
                        <ul class="ContactText20">
                            <li class="align1">
                                <h2 class="Header2About20">Curated DFS</h2>
                            </li>
                            <li><p class="PAbout20">We've taken daily fantasy sports to the next level by introducing a unique curation process. Our app will match you with other users based on your respective skill level, ensuring that you are competing against players of similar ability. This creates a fair and competitive environment for all of our users, making it easier for you to be successful.</p></li>
                            <li><img src={contact_2} alt="" class="contact120"/></li>
                        </ul>
                    </th>
                </tr>
                
                <tr>
                    <th>
                        <ul class="ContactText20">
                            <li class="align1">
                                <h2 class="Header2About20">Secure Network</h2>
                            </li>
                            <li><p class="PAbout20">We are committed to providing our users with a safe and secure platform to enjoy their daily fantasy sports experience. Our state-of-the-art security measures ensure that your information and fantasy activities are protected at all times.</p></li>
                            <li><img src={contact_3} alt="" class="contact120"/></li>
                        </ul>
                    </th>
                </tr>
            </table>
            <br />
        </div>


    )
}

export default About2;