import React from "react";
import {Link} from "react-router-dom";
import './Footer.css';
import banner from '../images/bannerlogo_resize.svg'
import OpenPopUp from './OpenPopUp';

function Footer (){
    return(
        
        <footer>
            <ul class="footer">
                <div class="tableFooter4">
                    <div class="tableFooter3">
                        {/* <p class="PFooter14">Must be 18+ | Concerned with your play? Call or text 1-800-522-4700</p> */}
                    </div>
                </div>
                <br /><br />
                <li class="lifoot"></li>
                <li class="lifoot">
                    <img src={banner} class="ParlayeCopyrightImage"/>
                    <div class="copyright">Copyright &copy; 2024 Parlaye Inc.</div>
                    <li class="copyright"><OpenPopUp /></li>
                </li>
                <li class="lifoot">
                    <Link to="/About"  class="footLink">About</Link><br /><br />
                    <a href="https://www.parlaye.com/#how-it-works" class="footLink">How it Works</a><br />
                </li>
                <li class="lifoot">
                    <Link to="/TermsOfUse"  class="footLink">Terms of Use</Link><br /><br />
                    <Link to="/PrivacyPolicy"  class="footLink">Privacy Policy</Link><br />
                </li>
                <li class="lifoot">
                    <Link to="/Contact"  class="footLink">Contact Us</Link><br />
                </li>
                <li class="lifoot"></li>
            </ul>
            <br /><br /><br />

        </footer>
    )
}

export default Footer