import React from "react";
import {Link} from "react-router-dom";
import './NavBar.css';
import banner from '../images/bannerlogo_resize.svg'
import OpenPopUp from './OpenPopUp';

function NavBar3 (){
    return(
        <ul class="fixedTop">
            <li><Link to="/" className="mid">Home</Link></li>
            <li><Link to="/About" className="mid">About</Link></li>
            <li><Link to="/Contact" className="mid">Contact Us</Link></li>
            <li><OpenPopUp /></li>
        </ul>
    )
}

export default NavBar3