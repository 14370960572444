import React from "react";
import {Link} from "react-router-dom";
import './Footer.css';
import banner from '../images/bannerlogo_resize.svg'
import OpenPopUp2 from './OpenPopUp2';

function Footer2 (){
    return(
        
        <footer>
            <div class="footer2">
                {/* <p class="PFooter15">Must be 18+ | Concerned with your play? Call or text 1-800-522-4700</p> */}
                <br />
                <div class="centerImg"><img src={banner} class="ParlayeCopyrightImage2"/></div>
                <div class="copyright2">Copyright &copy; 2024 Parlaye Inc.</div><br />
                <div class="copyright2"><OpenPopUp2 /></div><br />
                <Link to="/About"  class="footLink2">About</Link><br />
                <a href="https://www.parlaye.com/#how-it-works" class="footLink2">How it Works</a><br />
                <Link to="/TermsOfUse"  class="footLink2">Terms of Use</Link><br />
                <Link to="/PrivacyPolicy"  class="footLink2">Privacy Policy</Link><br />
                <Link to="/Contact"  class="footLink2">Contact Us</Link><br />
            </div>
            <br /><br /><br />

        </footer>
    )
}

export default Footer2